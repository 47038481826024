<!-- eslint-disable -->
<template>
  <div>
    <section>
      <b-collapse
        class="card"
        animation="slide"
        v-for="(collapse, index) of this.collapsibleWaypoints"
        :key="index"
        :open="index === 0"
        :aria-id="'waypoint-card' + index"
        :class="{
          'is-selected': isSelected(collapse),
          'is-call': !collapse.type,
        }"
      >
        <template #trigger="props">
          <div
            v-if="collapse.type === 'legs'"
            class="card-header"
            :class="{ 'active-header': isSelected(collapse) }"
            role="button"
            :aria-controls="'waypoint-card' + index"
            :aria-expanded="props.open"
          >
            <div class="card-header-title-container">
              <p class="card-header-title">
                Legs from waypoint {{ collapse.startWaypoint }}
                to waypoint
                {{ collapse.endWaypoint }}
              </p>
              <p>
                Speed:
                {{
                  collapse.speed === Infinity
                    ? "undefined"
                    : `${collapse.speed?.toFixed(2)} kt`
                }}
              </p>
              <p v-if="!!collapse.consumption">
                Consumption: {{ collapse.consumption?.toFixed(2) }} L/h
              </p>
              <p>Distance: {{ collapse.distance?.toFixed(2) }} NM</p>
              <p>
                Time:
                {{
                  collapse.duration.hours === 0 &&
                  collapse.duration.minutes === 0
                    ? "undefined"
                    : ""
                }}
                {{
                  collapse.duration.hours !== 0
                    ? `${collapse.duration.hours} ${
                        collapse.duration.hours === 1 ? "hour" : "hours"
                      }`
                    : ""
                }}
                {{
                  collapse.duration.minutes !== 0
                    ? `${collapse.duration.minutes} ${
                        collapse.duration.minutes === 1 ? "minute" : "minutes"
                      }`
                    : ""
                }}
              </p>
            </div>
            <a
              v-if="
                !collapse.type ||
                (collapse.type === 'legs' && !!collapse.waypoints)
              "
              class="card-header-icon"
            >
              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
              </b-icon>
            </a>
          </div>
          <div
            v-else
            class="card-header"
            :class="{ 'active-header': isSelected(collapse) }"
            role="button"
            :aria-controls="'waypoint-card' + index"
            :aria-expanded="props.open"
            @click="onSelect(collapse)"
          >
            <div class="card-header-title-container">
              <p class="card-header-title call-location">
                {{
                  collapse.call_location_str ||
                  `Call in ${collapse.call_location}`
                }}
              </p>
              <p>
                Day {{ index === 0 ? "1" : collapse.arrivalDay + 1
                }}{{
                  index !== 0 && collapse.arrivalDay !== collapse.depDay
                    ? ` > Day ${collapse.depDay + 1}`
                    : ""
                }}
              </p>
            </div>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
              </b-icon>
            </a>
          </div>
        </template>
        <div
          class="card-content"
          v-if="
            !collapse.type || (collapse.type === 'legs' && !!collapse.waypoints)
          "
        >
          <div
            class="content"
            @click="!collapse.type ? onSelect(collapse) : null"
          >
            <b-field v-if="!collapse.type" class="call-name">
              <p class="control">
                <span class="button is-small is-static control-label"
                  >Call</span
                >
              </p>
              <b-input
                :value="collapse.call_location_str"
                data-test="call-location"
                size="is-small"
                :disabled="cruiseReadOnly"
                expanded
                @change.native="
                  (e) => updateWp(collapse, 'call_location_str', e.target.value)
                "
              />
            </b-field>
            <b-table
              class="waypoints-table"
              :class="{ 'call-table': !collapse.type }"
              :data="collapse.type === 'legs' ? collapse.waypoints : [collapse]"
              :selected="selectedWaypoint ? selectedWaypoint : null"
              @select="onSelect"
            >
              <b-table-column v-slot="props" label="WP" data-test="wp_idx">
                {{ props.row?.sn }}
              </b-table-column>
              <b-table-column v-slot="props" label="Call">
                <div class="checkbox-container">
                  <b-checkbox
                    :value="props.row?.is_call"
                    data-test="is_call"
                    :disabled="
                      cruiseReadOnly ||
                      index === 0 ||
                      !props.row?.is_national_water
                    "
                    @input="(val) => updateWp(props.row, 'is_call', val)"
                  />
                  <span
                    class="small-text"
                    :class="{ subtle: !props.row?.is_national_water }"
                    >{{
                      !props.row?.is_national_water
                        ? "Waypoints in international waters cannot be calls"
                        : "This waypoint is a call"
                    }}</span
                  >
                </div>
              </b-table-column>

              <b-table-column
                v-slot="props"
                label="Latitude"
                data-test="lat_idx"
              >
                <div class="column">
                  <b-field class="legs-card">
                    <p class="control">
                      <span class="button is-small is-static control-label"
                        >lat.</span
                      >
                    </p>
                    <b-input
                      :value="props.row?.latitude?.toFixed(4)"
                      data-test="latitude"
                      size="is-small"
                      :disabled="cruiseReadOnly"
                      expanded
                      @change.native="
                        (e) => updateWp(props.row, 'latitude', e.target.value)
                      "
                    />
                  </b-field>

                  <b-field
                    class="desktop-only"
                    v-if="!props.row?.is_temp && !collapse.type"
                    :type="fieldType(props.row, 'arr_date')"
                  >
                    <p class="control">
                      <span class="button is-small is-static control-label"
                        >arrival</span
                      >
                    </p>
                    <p data-test="arr_date" class="control is-expanded">
                      <b-datetimepicker
                        ref="arr_date"
                        :value="props.row.arr_date"
                        data-test="arr_date"
                        placeholder="Pick date &amp; time…"
                        icon="calendar"
                        pack="fas"
                        size="is-small"
                        :disabled="cruiseReadOnly"
                        :datepicker="{ 'first-day-of-week': 1 }"
                        :timepicker="{}"
                        :datetime-formatter="
                          (d) => formatDateWithTZ(d, props.row.timezone)
                        "
                        :tz-offset="
                          getOffsetForDate(
                            props.row.arr_date,
                            props.row.timezone
                          )
                        "
                        :use-html5-validation="false"
                        @input="
                          (val) => {
                            datetimeVals['arr_date'] = val
                          }
                        "
                        @blur="
                          updateWp(
                            props.row,
                            'arr_date',
                            datetimeVals['arr_date']
                          )
                        "
                      />
                    </p>
                  </b-field>
                </div>
              </b-table-column>

              <b-table-column
                v-slot="props"
                label="Longitude"
                data-test="lon_idx"
              >
                <div class="column">
                  <b-field class="legs-card">
                    <p class="control">
                      <span class="button is-small is-static control-label"
                        >lon.</span
                      >
                    </p>
                    <b-input
                      :value="props.row?.longitude?.toFixed(4)"
                      data-test="longitude"
                      size="is-small"
                      :disabled="cruiseReadOnly"
                      expanded
                      @change.native="
                        (e) => updateWp(props.row, 'longitude', e.target.value)
                      "
                    />
                  </b-field>

                  <b-field
                    v-if="!props.row?.is_temp && !collapse.type"
                    class="desktop-only"
                    :type="fieldType(props.row, 'dep_date')"
                  >
                    <p class="control">
                      <span class="button is-small is-static control-label"
                        >departure</span
                      >
                    </p>
                    <p data-test="dep_date" class="control is-expanded">
                      <b-datetimepicker
                        ref="dep_date"
                        :value="props.row.dep_date"
                        data-test="dep_date"
                        placeholder="Pick date &amp; time…"
                        icon="calendar"
                        pack="fas"
                        size="is-small"
                        :disabled="cruiseReadOnly"
                        :datepicker="{ 'first-day-of-week': 1 }"
                        :timepicker="{}"
                        :datetime-formatter="
                          (d) => formatDateWithTZ(d, props.row.timezone)
                        "
                        :tz-offset="
                          getOffsetForDate(
                            props.row.dep_date,
                            props.row.timezone
                          )
                        "
                        :use-html5-validation="false"
                        @input="
                          (val) => {
                            datetimeVals['dep_date'] = val
                          }
                        "
                        @blur="
                          updateWp(
                            props.row,
                            'dep_date',
                            datetimeVals['dep_date']
                          )
                        "
                      />
                    </p>
                  </b-field>
                </div>
              </b-table-column>

              <b-table-column v-slot="props" label="" data-test="geoname_idx">
                <span
                  v-if="props.row?.is_national_water && props.row?.geoname"
                  data-test="national-waters"
                  class="small-text"
                  >National Waters of:
                  {{ props.row.geoname.display_name }}</span
                >
                <span v-else data-test="international-waters" class="small-text"
                  >International Waters</span
                >
                <p
                  v-if="collapse.timezone && !collapse.type"
                  class="timezone-small-text desktop-only"
                  data-test="timezone"
                >
                  {{ collapse.timezone }} time
                </p>
              </b-table-column>

              <b-table-column
                v-slot="props"
                label="Arrival"
                data-test="arrival_idx"
              >
                <b-field
                  :class="{ 'mobile-only': !collapse.type }"
                  class="legs-card"
                  v-if="!props.row?.is_temp"
                  :type="fieldType(props.row, 'arr_date')"
                >
                  <p class="control">
                    <span class="button is-small is-static control-label"
                      >arrival</span
                    >
                  </p>
                  <p data-test="arr_date" class="control is-expanded">
                    <b-datetimepicker
                      ref="arr_date"
                      :value="props.row.arr_date"
                      data-test="arr_date"
                      placeholder="Pick date &amp; time…"
                      icon="calendar"
                      pack="fas"
                      size="is-small"
                      :disabled="cruiseReadOnly"
                      :datepicker="{ 'first-day-of-week': 1 }"
                      :timepicker="{}"
                      :datetime-formatter="
                        (d) => formatDateWithTZ(d, props.row.timezone)
                      "
                      :tz-offset="
                        getOffsetForDate(props.row.arr_date, props.row.timezone)
                      "
                      :use-html5-validation="false"
                      @input="
                        (val) => {
                          datetimeVals['arr_date'] = val
                        }
                      "
                      @blur="
                        updateWp(
                          props.row,
                          'arr_date',
                          datetimeVals['arr_date']
                        )
                      "
                    />
                  </p>
                </b-field>
              </b-table-column>

              <b-table-column
                v-if="!collapse.type"
                v-slot="props"
                label="Departure"
                data-test="departure_idx"
              >
                <b-field
                  :class="{ 'mobile-only': !collapse.type }"
                  class="legs-card"
                  v-if="!props.row?.is_temp"
                  :type="fieldType(props.row, 'dep')"
                >
                  <p class="control">
                    <span class="button is-small is-static control-label"
                      >departure</span
                    >
                  </p>
                  <p data-test="dep_date" class="control is-expanded">
                    <b-datetimepicker
                      ref="dep_date"
                      :value="props.row.dep_date"
                      data-test="dep_date"
                      placeholder="Pick date &amp; time…"
                      icon="calendar"
                      pack="fas"
                      size="is-small"
                      :disabled="cruiseReadOnly"
                      :datepicker="{ 'first-day-of-week': 1 }"
                      :timepicker="{}"
                      :datetime-formatter="
                        (d) => formatDateWithTZ(d, props.row.timezone)
                      "
                      :tz-offset="
                        getOffsetForDate(props.row.dep_date, props.row.timezone)
                      "
                      :use-html5-validation="false"
                      @input="
                        (val) => {
                          datetimeVals['dep_date'] = val
                        }
                      "
                      @blur="
                        updateWp(
                          props.row,
                          'dep_date',
                          datetimeVals['dep_date']
                        )
                      "
                    />
                  </p>
                </b-field>
              </b-table-column>

              <b-table-column v-slot="props" label="" data-test="timezone_idx">
                <div class="geoname" :class="{ 'mobile-only': !collapse.type }">
                  <span
                    v-if="props.row?.timezone"
                    class="small-text"
                    data-test="timezone"
                    >{{ props.row.timezone }} time</span
                  >
                </div>
              </b-table-column>
              <b-table-column
                v-if="collapse.type === 'legs'"
                v-slot="props"
                label=""
              >
                <div
                  v-if="!cruiseReadOnly"
                  class="leg-delete-button"
                  data-test="delete"
                  @click="onDeleteClick(props.row)"
                >
                  <b-button type="is-danger" size="is-small">
                    <b-icon icon="trash-alt" pack="fas"></b-icon>
                  </b-button>
                </div>
              </b-table-column>
            </b-table>

            <div v-if="!collapse.type" class="call-footer-container">
              <p>
                Call duration: {{ collapse.duration.days }} days,
                {{ collapse.duration.hours }} hours,
                {{ collapse.duration.minutes }} minutes
              </p>
              <b-button
                v-if="!cruiseReadOnly"
                type="is-danger"
                size="is-small"
                @click="onDeleteClick(collapse)"
              >
                <span class="delete-button-content">
                  <b-icon icon="trash-alt" pack="fas"></b-icon>
                  Delete Waypoint
                </span>
              </b-button>
            </div>
          </div>
        </div>
      </b-collapse>
    </section>
    <div
      v-if="callWaypoints && callWaypoints.length"
      class="has-text-centered pt-5"
    >
      Total distance of the cruise:
      <strong
        >{{
          this.totalDistance !== 0 ? this.totalDistance : "n/a"
        }}
        miles</strong
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Actions from "@/store/actions"
import DateFormatters from "@/helpers/date_formatters"
import dayjs from "dayjs"
import WaypointForm from "./WaypointForm.vue"

/* eslint-disable */
export default {
  name: "WaypointsList",
  components: {
    WaypointForm,
  },
  props: {
    cruiseReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datetimeVals: {},
    }
  },
  computed: {
    ...mapState({
      waypoints: (state) => state.waypoint.waypoints,
      firstDepartureDate: (state) =>
        dayjs(state.waypoint.waypoints[0]?.dep_date),
    }),
    totalDistance: function () {
      return this.waypoints[0]?.distance_to_go?.toFixed(2) || 0
    },
    collapsibleWaypoints: function () {
      if (this.waypoints.length === 0) return []

      const groupedEntities = []

      for (let i = 0; i <= this.waypoints.length - 1; ) {
        if (this.waypoints[i]?.is_call) {
          const arrival = dayjs(this.waypoints[i].arr_date)
          const departure = dayjs(this.waypoints[i].dep_date)
          const arrivalDay = arrival.diff(this.firstDepartureDate, "day")
          const depDay = departure.diff(this.firstDepartureDate, "day")

          const duration = departure.diff(arrival, "minutes")
          const days = Math.floor(duration / 1440)
          const hours = Math.floor((duration - days * 1440) / 60)
          const minutes = duration - (days * 1440 + hours * 60)

          groupedEntities.push({
            ...this.waypoints[i],
            arrivalDay,
            depDay,
            duration: { days, hours, minutes },
          })

          if (i < this.waypoints.length) {
            if (this.waypoints[i + 1]?.is_call) {
              const depTime = dayjs(this.waypoints[i]?.dep_date)
              const arrTime = dayjs(this.waypoints[i + 1]?.arr_date)

              // difference in hours and minutes
              const duration = arrTime.diff(depTime, "minutes")
              const hours = Math.floor(duration / 60)
              const minutes = duration - hours * 60

              // distance
              const distance =
                this.waypoints[i].distance_to_go -
                this.waypoints[i + 1].distance_to_go
                
              // speed
              const speed = (distance * 60) / duration

              const nextLeg = {
                type: "legs",
                distance,
                startWaypoint: this.waypoints[i].sn,
                endWaypoint: this.waypoints[i + 1].sn,
                speed,
                duration: {
                  hours,
                  minutes,
                },
                waypoints: null,
                consumption: this.yachtConsumption(speed),
              }

              groupedEntities.push(nextLeg)
            }
          }

          i++
        } else {
          const tempWaypoints = []
          tempWaypoints.push(this.waypoints[i])

          for (let j = i + 1; j <= this.waypoints.length - 1; j++) {
            if (!this.waypoints[j]?.is_call) {
              tempWaypoints.push(this.waypoints[j])
            } else {
              break
            }
          }

          const distance =
            this.waypoints[i - 1]?.distance_to_go -
            (this.waypoints[i + tempWaypoints.length]?.distance_to_go || 0)

          const depTime = dayjs(this.waypoints[i - 1]?.dep_date)
          const arrTime = dayjs(
            this.waypoints[i + tempWaypoints.length]?.arr_date
          )

          // difference in hours and minutes
          const duration = arrTime.diff(depTime, "minutes")
          const hours = Math.floor(duration / 60)
          const minutes = duration - hours * 60

          // speed
          const speed = distance / (duration / 60)

          groupedEntities.push({
            type: "legs",
            distance,
            duration: {
              hours,
              minutes,
            },
            speed,
            startWaypoint: this.waypoints[i - 1]?.sn,
            endWaypoint:
              this.waypoints[i + tempWaypoints.length]?.sn ||
              tempWaypoints[tempWaypoints.length - 1].sn,
            waypoints: tempWaypoints,
            consumption: this.yachtConsumption(speed),
          })

          i = i + tempWaypoints.length
        }
      }
      return groupedEntities
    },
    selectedWaypoint: function () {
      var sel = this.$store.state.waypoint.selectedWaypoint
      if (sel) {
        return this.waypoints.find((wp) => wp.id === sel.id)
      }
      return false
    },
    callWaypoints() {
      return this.waypoints.filter((wp) => wp.is_call)
    },
    yachtCruiseName() {
      if (this.$store.state.cruise.cruise.yacht) {
        return this.$store.state.cruise.cruise.yacht.name
      } else {
        return null
      }
    },
    yachtSelectedInfos() {
      if (this.yachtCruiseName) {
        return this.$store.state.yacht.yachts.filter(
          (p) => p.name === this.yachtCruiseName
        )
      } else {
        return null
      }
    },
  },
  methods: {
    ...DateFormatters,
    onSelect(row) {
      this.$store.dispatch(Actions.SET_SELECTED_WAYPOINT, row)
    },
    yachtConsumption(speed) {
      if (
        this.yachtSelectedInfos &&
        this.yachtSelectedInfos[0] &&
        this.yachtSelectedInfos[0].speed_consumption_table
      ) {
        var tabConsumption = this.yachtSelectedInfos[0].speed_consumption_table

        if (
          tabConsumption &&
          tabConsumption != [] &&
          tabConsumption[Math.round(speed)]
        ) {
          return tabConsumption[Math.round(speed)]
        } else if (
          tabConsumption &&
          tabConsumption != [] &&
          !tabConsumption[Math.round(speed)]
        ) {
          var maxSpeed = Object.keys(tabConsumption).length - 1
          return tabConsumption[maxSpeed]
        }
      }
    },
    updateWp(wp, prop, val, confirmed = false) {
      if (this.cruiseReadOnly) return
      if (!confirmed) {
        if (prop === "is_call" && !val && wp.has_disembs) {
          this.$buefy.dialog.confirm({
            message:
              "This call has passengers embarking/disembarking. This action will remove these emb/disembs.",
            confirmText: "Proceed anyway",
            type: "is-warning",
            onConfirm: () => this.updateWp(wp, prop, val, true),
          })
          return false
        }
      }
      if (prop[0] === "+") {
        prop = prop.slice(1)
        val.setHours(wp[prop].getHours())
        val.setMinutes(wp[prop].getMinutes())
        val.setSeconds(wp[prop].getSeconds())
        val.setMilliseconds(wp[prop].getMilliseconds())
      }
      const newWp = Object.assign({ [prop]: val })
      this.$store.dispatch(Actions.SAVE_WAYPOINT, {
        waypointId: wp.id,
        waypointData: newWp,
      })
    },
    onDeleteClick(wp) {
      if (wp.has_disembs) {
        this.$buefy.dialog.confirm({
          message:
            "This call has passengers embarking/disembarking. This action will remove these emb/disembs.",
          confirmText: "Proceed anyway",
          type: "is-warning",
          onConfirm: () => this.$store.dispatch(Actions.DELETE_WAYPOINT, wp.id),
        })
        return
      }

      this.$store.dispatch(Actions.DELETE_WAYPOINT, wp.id)
    },
    fieldType(wp, field) {
      if (field in wp.errors) {
        return "is-danger"
      } else if (field in wp.warnings) {
        return "is-warning"
      } else {
        return ""
      }
    },
    coordinates(value) {
      return parseFloat(value).toFixed(4)
    },
    distance(value) {
      return parseFloat(value).toFixed(2)
    },
    isSelected(wp) {
      if (!this.selectedWaypoint) return false
      if (wp.type === "legs") {
        const ids = wp.waypoints?.map((i) => i?.id)
        return ids?.includes(this.selectedWaypoint.id)
      } else {
        return wp.id === this.selectedWaypoint.id
      }
    },
  },
}
</script>
<style lang="scss">
.collapse:not(.show) {
  display: block;
}
div.dropdown-item:active,
div.dropdown-item.active {
  background-color: var(--bs-dropdown-link-hover-bg);
}

.content {
  .field.call-name {
    padding: 0;
  }
}

.collapse.card {
  margin-bottom: 1rem;
}

.collapse.card.is-selected.is-call {
  border: 2px solid #286079;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.collapse {
  .active-header {
    background-color: #286079;
    color: #fff;

    .card-header-title {
      color: #fff !important;
    }
  }
  .card-header {
    justify-content: space-between;

    .card-header-title {
      padding: 0;
    }

    .card-header-title.call-location {
      color: #286079;
    }

    .card-header-title-container {
      display: flex;
      align-items: center;
      gap: 1rem;
      min-height: 42px;

      p:not(.card-header-title) {
        font-size: 0.9rem;
      }
    }
  }

  .card-content {
    padding: 1rem;
  }
}

.call-footer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  .delete-button-content {
    display: flex;
    gap: 1rem;
  }
}

.waypoints-table {
  .table {
    tr.is-selected {
      background-color: transparent;
      color: initial;

      td {
        border-top: 2px solid #286079;
        border-bottom: 2px solid #286079;
      }

      td:first-of-type {
        border-left: 2px solid #286079;
      }

      td:last-of-type {
        border-right: 2px solid #286079;
      }

      .checkbox input + .check {
        border-color: hsl(0deg, 0%, 48%) !important;
      }
    }
  }
  thead {
    display: none;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
  }
  .small-text {
    font-size: 0.8rem;
  }
  .subtle {
    opacity: 0.8;
  }
  .legs-card {
    .field.has-addons {
      align-items: center;
    }
    .control {
      margin-bottom: 0;
    }
  }

  .mx-datepicker {
    // display: inline-flex;
    .mx-calendar-icon {
      height: 1.5em;
    }
  }

  .wlist-input {
    display: inline-flex;
    width: 110px;
  }

  td {
    vertical-align: middle !important;
    // padding: 0.2rem;
    @include touch {
      padding: 0.1rem !important;
    }
  }
}

.waypoints-table.call-table {
  .column {
    padding: 0;
  }
  .timezone-small-text {
    font-size: 0.8rem;
    margin-top: 1.5rem;
  }
  td {
    vertical-align: top !important;
    // padding: 0.2rem;
    @include touch {
      padding: 0.1rem !important;
    }
  }
}

@media (max-width: 900px) {
  .collapse {
    .card-header {
      .card-header-title-container {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .b-table.waypoints-table {
    .table-wrapper.has-mobile-cards {
      tr.is-selected {
        border: 2px solid #286079;
        background-color: transparent;
        color: initial;

        .checkbox input + .check {
          border-color: hsl(0deg, 0%, 48%);
        }
      }
      tr:not(.detail):not(.is-empty):not(.table-footer) {
        td {
          justify-content: flex-start;
          border: none;

          .field.legs-card {
            flex: 1;
          }

          .control {
            .control-label {
              display: none;
            }
          }

          .desktop-only {
            display: none;
          }

          .column {
            padding: 0;

            .legs-card {
              .control {
                .control-label {
                  display: none;
                }
              }
            }
          }

          .leg-delete-button {
            flex: 1;
          }
        }
        td:before {
          width: 30%;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}
</style>
